import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Schedule from './Schedule';

import {
  changeCategory,
  addCategory,
  removeCategory,
  dateChange,
  addSlot,
  removeSlot,
  addItem,
  rearrangeItems,
  designChange,
  duplicateItem
} from './actions';

import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appname: ownProps.match.params.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const router = {
    history: ownProps.history,
    location: ownProps.location,
    match: ownProps.match,
  };

  return {
    onAddCategory: label =>
      dispatch(
        addCategory(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          label,
        ),
      ),
    onRemoveCategory: filterId =>
      dispatch(
        removeCategory(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          filterId,
        ),
      ),
    onCategoryChange: (filterId, field, value) =>
      dispatch(
        changeCategory(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          filterId,
          field,
          value,
        ),
      ),
    onDateChange: (intinerary, value) =>
      dispatch(
        dateChange(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          intinerary,
          value,
        ),
      ),
    onAddSlot: ts =>
      dispatch(
        addSlot(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          ts,
          router,
        ),
      ),
    onRemoveSlot: itinerary =>
      dispatch(
        removeSlot(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          itinerary,
        ),
      ),
    onAddItem: itinerary =>
      dispatch(
        addItem(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          itinerary,
          router,
        ),
      ),
    onDuplicateItem: (itinerary, data) =>
      dispatch(
        duplicateItem(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          itinerary,
          router,
          data,
        ),
      ),
    onRemoveItem: (itinerary, item) => {
      const query = {
        itinerary,
        item,
        ...queryString.parse(ownProps.location.search),
      };

      dispatch(
        launchRemoveDialog(ownProps.match.params.appname, query, 'ITEM'),
      );
    },
    rearrangeItems: (id, siblingId, itinerary) =>
      dispatch(
        rearrangeItems(
          id,
          siblingId,
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          itinerary,
        ),
      ),
    onDesignChange: (type, value) =>
      dispatch(
        designChange(
          ownProps.match.params.appname,
          ownProps.component.key,
          type,
          value,
        ),
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Schedule),
);
